import React, { useMemo } from 'react';
import { IconButton, Badge, makeStyles } from '@material-ui/core';
import Image from 'next/image';
import { useReactiveVar } from '@apollo/client';

import cartIcon from 'public/icons/shopping-cart.svg';
import { cartVar } from 'utils/apollo/vars/cartVar';

interface CartHeaderIconProps {
  onClick: () => void;
  className?: string;
}

const CartHeaderIcon: React.FC<CartHeaderIconProps> = ({
  onClick,
  className = ''
}): JSX.Element => {
  const classes = useStyles();

  const cartItems = useReactiveVar(cartVar);

  const numberOfItems = useMemo(
    () => cartItems.reduce((acc, { quantity }) => acc + quantity, 0),
    [cartItems]
  );

  return (
    <IconButton aria-label="cart" color="primary" onClick={onClick} className={className}>
      <Badge
        badgeContent={numberOfItems}
        color="secondary"
        invisible={!numberOfItems}
        max={99}
        classes={{
          badge: classes.badge
        }}
      >
        <Image src={cartIcon} alt="Cart" height={28} width={29} />
      </Badge>
    </IconButton>
  );
};

const useStyles = makeStyles((theme) => ({
  badge: {
    color: theme.palette.common.white
  }
}));

export default CartHeaderIcon;
